import { fetchNetCredited, transferNetCredited } from '../../../services/APIClient'
import { useEffect, useState } from 'react'
import { SimpleCard } from '../../../../matx'
import { Select, Table, TableBody, TableCell, TableHead, TableRow, MenuItem } from '@mui/material'
import { currencyWithTwoDecimals } from '../../../services/FormatterService'
import Loading from '../../../components/Loading'

export default function NetTransfer({ date }) {
  const [netCreditedData, setNetCreditedData] = useState(undefined)
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    setIsloading(true)
    let mounted = true
    fetchNetCredited(date)
      .then((netCreditedDataResponse) => {
        if (mounted) {
          setNetCreditedData(netCreditedDataResponse)
        }
      })
      .finally(() => {
        if (mounted) setIsloading(false)
      })
    return () => (mounted = false)
  }, [date])

  const isValidManuallyTransferRequest = (request) => {
    return (
      request.executionDate !== null &&
      request.executionDate !== '' &&
      request.providerTransactionId !== null &&
      request.providerTransactionId !== ''
    )
  }

  const transferCreditAmount = async (bankAccountCategory, request) => {
    if (date === null || date === undefined) {
      window.alert('First choose a date')
      return
    }

    if (request.isManuallyExecuted && !isValidManuallyTransferRequest(request)) {
      window.alert('For transfer manually please fill the provider transaction ID and executed At.')
      return
    }

    if (window.confirm('Are you sure you want transfer those amounts?')) {
      setIsloading(true)

      const requestData = {
        requestedDate: date,
        bankAccountCategory,
        ...(request.isManuallyExecuted ? request : {}),
      }

      transferNetCredited(requestData).finally(() => {
        setIsloading(false)
      })
    }
  }

  const b2bTradeToTransfer = netCreditedData?.filter(
    (item) => item.businessLine === 'IMPORTATION' || item.businessLine === 'PHYSICAL_GOODS_USING_PAYMENT_LINK',
  )

  const nonB2bTradeToTransfer = netCreditedData?.filter(
    (item) => item.businessLine === 'VIRTUAL_GOODS_WITH_LOW_TRANSACTION_AMOUNT' || item.businessLine === 'GAMING',
  )

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div style={{ display: 'grid', gridRowGap: '10px' }}>
          <NetCreditedTable
            title="B2B Trade"
            netCreditedData={b2bTradeToTransfer}
            onTransfer={(request) => transferCreditAmount('B2B_TRADE', request)}
          />
          <NetCreditedTable
            title="B2B non Trade"
            netCreditedData={nonB2bTradeToTransfer}
            onTransfer={(request) => transferCreditAmount('B2B_NON_TRADE', request)}
          />
        </div>
      )}
    </>
  )
}

function NetCreditedTable({ title, netCreditedData, onTransfer }) {
  const [request, setRequest] = useState({
    isManuallyExecuted: false,
    executionDate: null,
    providerTransactionId: null,
    bankTransferType: 'PIX',
    payinTransactionIdsRelativeOfManualBankTransfer: null,
  })

  if (netCreditedData == null) {
    return <></>
  }

  let totalToTransfer = netCreditedData.reduce((acc, item) => acc + item.creditAmount, 0).toFixed(2)

  let accountingDate = ''
  if (netCreditedData[0] != null) {
    accountingDate = netCreditedData[0]?.accountingDate
  }

  return (
    <div className="First card">
      <SimpleCard title={`Net credited amounts`}>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <p>
              Total {title} to transfer on {accountingDate}: R$ {totalToTransfer}
            </p>
          </div>
          <div>
            <tr>
              <td className="column-table">
                <div>
                  <label>It was manually transfered?: </label>
                  <input
                    type="radio"
                    value={true}
                    name={`active-${title}`}
                    defaultChecked={request.isManuallyExecuted}
                    onChange={(_) => setRequest({ ...request, isManuallyExecuted: true })}
                    required
                  />{' '}
                  Yes
                  <input
                    type="radio"
                    value={false}
                    name={`active-${title}`}
                    defaultChecked={!request.isManuallyExecuted}
                    onChange={(_) => setRequest({ ...request, isManuallyExecuted: false })}
                    required
                  />{' '}
                  No
                </div>
              </td>
            </tr>
            <button type="button" className="btn btn-success" onClick={() => onTransfer(request)}>
              {' '}
              TRANSFER
            </button>
          </div>
        </div>

        {request.isManuallyExecuted ? (
          <div>
            <tr>
              <td className="column-table">Provider request ID:</td>
              <td>
                <input
                  className="credit-net-amount-input-text"
                  name="providerTransactionId"
                  onChange={(event) => setRequest({ ...request, providerTransactionId: event.target.value })}
                  id="providerTransactionId"
                  type="text"
                  placeholder="Provider transaction ID"
                  defaultValue={request.providerTransactionId}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Executed at: </td>
              <td>
                <input
                  style={{ maxWidth: 250 }}
                  className="account-register-input-text"
                  name="executionDate"
                  onChange={(event) => setRequest({ ...request, executionDate: event.target.value })}
                  step="any"
                  id="executionDate"
                  type="date"
                  placeholder="Executed at: "
                  defaultValue={request.executionDate}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Bank transfer type: </td>
              <td>
                <Select
                  required
                  value={request.bankTransferType}
                  name="bankTransferType"
                  id="bankTransferType"
                  onChange={(e) => setRequest({ ...request, bankTransferType: e.target.value })}
                  options={['PIX', 'BANK_TRANSFER']}
                  className="select"
                >
                  <MenuItem value="BANK_TRANSFER">TED/TEF</MenuItem>
                  <MenuItem value="PIX">PIX</MenuItem>
                </Select>
              </td>
            </tr>
            <tr>
              <td className="column-table">Payin transaction ids relative of manual bank transfer:</td>
              <td>
                <input
                  className="credit-net-amount-input-text"
                  name="payinTransactionIdsRelativeOfManualBankTransfer"
                  onChange={(event) =>
                    setRequest({ ...request, payinTransactionIdsRelativeOfManualBankTransfer: event.target.value })
                  }
                  id="payinTransactionIdsRelativeOfManualBankTransfer"
                  type="text"
                  placeholder="48965,49012,49013,49015,49020"
                  defaultValue={request.payinTransactionIdsRelativeOfManualBankTransfer}
                  required
                />
              </td>
            </tr>
          </div>
        ) : (
          <></>
        )}

        <Table style={{ whiteSpace: 'pre' }}>
          <TableHead>
            <TableRow>
              <TableCell className="px-0">Merchant soft descriptor</TableCell>
              <TableCell className="px-0">BusinessLine</TableCell>
              <TableCell className="px-0">Credit amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {netCreditedData.map((item) => (
              <TableRow key={`row${item.merchantSoftDescriptor}`}>
                <TableCell key={`Merchant${item.merchantSoftDescriptor}`} className="px-0">
                  {item.merchantSoftDescriptor}
                </TableCell>
                <TableCell key={`BusinessLine${item.merchantSoftDescriptor}`} className="px-0">
                  {item.businessLine}
                </TableCell>
                <TableCell key={`CreditAmount${item.merchantSoftDescriptor}`} className="px-0">
                  {currencyWithTwoDecimals(item.creditAmount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SimpleCard>
    </div>
  )
}
