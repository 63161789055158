import React from 'react'
import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'

class BrazilBankInput extends Component {
  constructor(props) {
    super(props)

    this.state = props.item || {}
    this.onChange = props.onChange
  }

  onAnyChanged = (change) => {
    let newState = { ...this.state, ...change }
    this.setState(newState)
    this.onChange(newState)
  }

  onChangeBankCode = (event) => {
    this.onAnyChanged({ bankCode: event.target.value })
  }

  onChangeAgency = (event) => {
    this.onAnyChanged({ agency: event.target.value })
  }

  onChangeAccountNumber = (event) => {
    this.onAnyChanged({ accountNumber: event.target.value })
  }

  onChangeVerifyingDigit = (event) => {
    this.onAnyChanged({ verifyingDigit: event.target.value })
  }

  onChangePix = (event) => {
    this.onAnyChanged({ pix: event.target.value })
  }

  onChangeBeneficiaryName = (event) => {
    this.onAnyChanged({ beneficiaryName: event.target.value })
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <BTable className="center">
            <tbody>
              <tr>
                <td colSpan="2">
                  <h4>Brazil Bank info:</h4>
                </td>
              </tr>
              <tr>
                <td className="column-table">Bank Code:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    onChange={this.onChangeBankCode}
                    required
                    name="bankCode"
                    maxLength="999"
                    id="bankCode"
                    type="number"
                    placeholder="Bank Code"
                    defaultValue={this.state.bankCode}
                  />
                </td>
              </tr>
              <tr>
                <td>Branch:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="agency"
                    required
                    maxLength="10"
                    onChange={this.onChangeAgency}
                    id="agency"
                    type="text"
                    placeholder="Branch"
                    defaultValue={this.state.agency}
                  />
                </td>
              </tr>
              <tr>
                <td>Account Number:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="accountNumber"
                    maxLength="10"
                    required
                    onChange={this.onChangeAccountNumber}
                    id="accountNumber"
                    type="text"
                    placeholder="Account Number"
                    defaultValue={this.state.accountNumber}
                  />
                </td>
              </tr>
              <tr>
                <td>Verifying Digit:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="verifyingDigit"
                    maxLength="10"
                    required
                    onChange={this.onChangeVerifyingDigit}
                    id="verifyingDigit"
                    type="text"
                    placeholder="Verifying Digit"
                    defaultValue={this.state.verifyingDigit}
                  />
                </td>
              </tr>
              <tr>
                <td>Pix:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="pix"
                    maxLength="100"
                    required
                    onChange={this.onChangePix}
                    id="pix"
                    type="text"
                    placeholder="Pix"
                    defaultValue={this.state.pix}
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Beneficiary name:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="beneficiaryName"
                    maxLength="100"
                    onChange={this.onChangeBeneficiaryName}
                    id="beneficiaryName"
                    type="text"
                    placeholder="Beneficiary name"
                    required
                    defaultValue={this.state.beneficiaryName}
                  />
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
      </React.Fragment>
    )
  }
}

export default BrazilBankInput
