import { useEffect, useState } from 'react'
import BTable from 'react-bootstrap/Table'
import { TablePagination } from '@mui/material'
import Loading from './Loading'

export default function PaginatedTable({ queryParams, query, columns, Rows }) {
  const [lastItems, setLastItems] = useState(null)
  const [page, setPage] = useState(0)

  useEffect(() => {
    query(page, 10, queryParams).then((resp) => {
      setLastItems(resp)
    })
  }, [queryParams, query, page])

  return (
    <>
      {lastItems === null ? (
        <Loading />
      ) : lastItems.content.length <= 0 ? (
        <div>
          <p style={{ color: '#000000' }}>Empty</p>
        </div>
      ) : (
        <>
          <BTable striped borderless hover>
            <thead>
              <tr>
                {columns.map((name) => (
                  <th key={name}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <Rows content={lastItems.content} />
            </tbody>
          </BTable>
          <TablePagination
            className="px-16"
            rowsPerPageOptions={[10]}
            component="div"
            count={lastItems.totalElements}
            rowsPerPage={10}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima página',
            }}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </>
      )}
    </>
  )
}
