import { useState, useEffect } from 'react'
import { SimpleCard } from '../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import {
  cutomerDetails,
  payoutTransactionWithFilters,
  transactionPagination,
  payeePagination,
} from '../../services/APIClient'
import 'bootstrap/dist/css/bootstrap.min.css'
import { currencyWithTwoDecimals, convertToBrazilianDateTimeZone } from '../../services/FormatterService'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { NavLink } from 'react-router-dom'
import Loading from '../../components/Loading'
import PaginatedTable from '../../components/PaginatedTable'

export function CustomerDetails({ id }) {
  const [details, setDetails] = useState(null)

  useEffect(() => {
    cutomerDetails(id).then((resp) => {
      setDetails(resp)
    })
  }, [id])

  return (
    <div className="First card">
      <SimpleCard title="Customer Details">
        <div>
          {details === null ? (
            <Loading />
          ) : (
            <Container>
              <h5>Customer:</h5>
              <BTable striped bordered hover>
                <tbody>
                  <tr>
                    <td>Id</td>
                    <td>{details.id}</td>
                  </tr>
                  <tr>
                    <td>Document id</td>
                    <td>{details.documentId}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{details.name}</td>
                  </tr>
                  <tr>
                    <td>Last KYC result</td>
                    <td>{details.result}</td>
                  </tr>
                  <tr>
                    <td>Last KYC result details</td>
                    <td>{details.resultDetails}</td>
                  </tr>

                  <tr>
                    <td>First Paid Payin Transaction Date</td>
                    <td>{convertToBrazilianDateTimeZone(details.firstPayinPaidTransactionDateTime)}</td>
                  </tr>
                  <tr>
                    <td>Last Paid Payin Transaction Date</td>
                    <td>{convertToBrazilianDateTimeZone(details.lastPayinPaidTransactionDateTime)}</td>
                  </tr>
                  <tr>
                    <td>Number of payin transactions</td>
                    <td>{details.numberOfPayinTransactions}</td>
                  </tr>

                  <tr>
                    <td>First Paid Payout Transaction Date</td>
                    <td>{convertToBrazilianDateTimeZone(details.firstPayoutPaidTransactionDateTime)}</td>
                  </tr>
                  <tr>
                    <td>Last Paid Payout Transaction Date</td>
                    <td>{convertToBrazilianDateTimeZone(details.lastPayoutPaidTransactionDateTime)}</td>
                  </tr>
                  <tr>
                    <td>Number of Payout transactions</td>
                    <td>{details.numberOfPayoutTransactions}</td>
                  </tr>
                </tbody>
              </BTable>

              <AccumulatedValues details={details} />

              <LastPayinTransactions customerId={id} />
              <LastPayoutTransactions customerId={id} />
              <LastPayeeMerchants customerId={id} />
            </Container>
          )}
        </div>
      </SimpleCard>
    </div>
  )
}

function AccumulatedValues({ details }) {
  const columns = ['Accumulated by', 'Payin payment', 'Refunded', 'Payout']

  return (
    <div className="my-4">
      <h5>Accumulated values:</h5>
      <Table style={{ whiteSpace: 'pre' }}>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell key={item} className="px-0">
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="px-0 capitalize">Calendar year</TableCell>
            <TableCell className="px-0 capitalize">{currencyWithTwoDecimals(details.yearlyPayinAmount)}</TableCell>
            <TableCell className="px-0 capitalize">{currencyWithTwoDecimals(details.yearlyRefundAmount)}</TableCell>
            <TableCell className="px-0 capitalize">{currencyWithTwoDecimals(details.yearlyPayoutAmount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="px-0 capitalize">Total</TableCell>
            <TableCell className="px-0 capitalize">{currencyWithTwoDecimals(details.totalPayinAmount)}</TableCell>
            <TableCell className="px-0 capitalize">{currencyWithTwoDecimals(details.totalRefundAmount)}</TableCell>
            <TableCell className="px-0 capitalize">{currencyWithTwoDecimals(details.totalPayoutAmount)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

function LastPayinTransactions({ customerId }) {
  const columns = ['tid', 'Merchant name', 'Payment method', 'Status', 'Amount', 'Creation date']

  const TransactionRows = ({ content }) =>
    content.map((transaction) => (
      <tr key={transaction.id}>
        <td>
          <NavLink to={`/payin/transaction/${transaction.id}`} end>
            {transaction.id}
          </NavLink>
        </td>
        <td>{transaction.softDescriptor}</td>
        <td>{transaction.paymentMethod}</td>
        <td>{transaction.status}</td>
        <td>{currencyWithTwoDecimals(transaction.amount)}</td>
        <td>{convertToBrazilianDateTimeZone(transaction.createdAt)}</td>
      </tr>
    ))

  return (
    <>
      <h5 style={{ paddingTop: '30px' }}>Last ten payin transactions:</h5>
      <PaginatedTable
        queryParams={{ customerId }}
        query={transactionPagination}
        columns={columns}
        Rows={TransactionRows}
      />
    </>
  )
}

function LastPayeeMerchants({ customerId }) {
  const columns = ['Payee merchant id', 'Creation date']

  const PayeeRows = ({ content }) =>
    content.map((payeeMerchant) => (
      <tr key={payeeMerchant.id}>
        <td>
          <NavLink to={`/payee-merchant/${payeeMerchant.id}`} end>
            {payeeMerchant.id}
          </NavLink>
        </td>
        <td>{convertToBrazilianDateTimeZone(payeeMerchant.createdAt)}</td>
      </tr>
    ))

  return (
    <>
      <h5 style={{ paddingTop: '30px' }}>Last ten payee merchants:</h5>
      <PaginatedTable queryParams={{ customerId }} query={payeePagination} columns={columns} Rows={PayeeRows} />
    </>
  )
}

function LastPayoutTransactions({ customerId }) {
  const columns = ['tid', 'Merchant name', 'Status', 'Amount', 'Creation date']

  const PayoutRows = ({ content }) =>
    content.map((payoutTransaction) => (
      <tr key={payoutTransaction.id}>
        <td>
          <NavLink to={`/payout/transaction/${payoutTransaction.id}`} end>
            {payoutTransaction.id}
          </NavLink>
        </td>
        <td>{payoutTransaction.payeeMerchant.merchantSoftDescriptor}</td>
        <td>{payoutTransaction.status}</td>
        <td>{currencyWithTwoDecimals(payoutTransaction.amount)}</td>
        <td>{convertToBrazilianDateTimeZone(payoutTransaction.createdAt)}</td>
      </tr>
    ))

  return (
    <>
      <h5 style={{ paddingTop: '30px' }}>Last ten payout transactions:</h5>
      <PaginatedTable
        queryParams={{ customerId }}
        query={payoutTransactionWithFilters}
        columns={columns}
        Rows={PayoutRows}
      />
    </>
  )
}

export default CustomerDetails
