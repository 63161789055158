import { useEffect, useState } from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { accountBalances } from '../../../../services/APIClient'
import Loading from '../../../../components/Loading'
import { currencyWithTwoDecimals } from '../../../../services/FormatterService'

function Balances({ merchantId }) {
  const [date, setDate] = useState(null)
  const [payinBalances, setPayinBalances] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [maxDate, setMaxDate] = useState(null)

  useEffect(() => {
    let mounted = true

    setIsLoading(true)
    accountBalances(merchantId).then((merchantBalanceResponse) => {
      if (mounted) {
        setPayinBalances(merchantBalanceResponse)
        setMaxDate(merchantBalanceResponse.date)
        setIsLoading(false)
      }
    })
    return () => (mounted = false)
  }, [merchantId])

  function retrieveNewBalance() {
    setIsLoading(true)
    accountBalances(merchantId, date).then((merchantBalanceResponse) => {
      setPayinBalances(merchantBalanceResponse)
      setIsLoading(false)
    })
  }

  const columns = [
    'Balance Type',
    'Available',
    'Scheduled',
    'Pending Correct Items',
    'Guarantee Deposit',
    'Chargeback reserve',
  ]

  if (maxDate === null) {
    return <></>
  }
  function content() {
    if (isLoading) {
      return <Loading />
    }
    if (payinBalances?.payinBalances === undefined) {
      return (
        <div>
          <p style={{ color: '#000000' }}>Empty</p>
        </div>
      )
    }
    return (
      <Table style={{ whiteSpace: 'pre' }}>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell key={item} className="px-0">
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <BalanceRow key={'payin'} balanceType={'payin'} balance={payinBalances.payinBalances} />
        </TableBody>
      </Table>
    )
  }

  return (
    <div className="my-4">
      <div style={{ float: 'right' }}>
        <input type="date" id="date" name="date" onChange={(event) => setDate(event.target.value)} max={maxDate} />
        <button onClick={() => retrieveNewBalance()}>Choose other balance date</button>
      </div>
      <h5>Balance {payinBalances.date}:</h5>
      {content()}
    </div>
  )
}

function BalanceRow({ balanceType, balance }) {
  return (
    <TableRow key={`balance-${balanceType}`}>
      <TableCell className="px-0 capitalize">{balanceType}</TableCell>
      <TableCell className="px-0 capitalize" style={{ fontWeight: 'bold' }}>
        {formatBalanceToDisplay(balance.AVAILABLE)}
      </TableCell>
      <TableCell className="px-0 capitalize">{formatBalanceToDisplay(balance.SCHEDULED)}</TableCell>
      <TableCell className="px-0 capitalize">{formatBalanceToDisplay(balance.PENDING_ITEMS_RESERVE)}</TableCell>
      <TableCell className="px-0 capitalize">{formatBalanceToDisplay(balance.GUARANTEE_DEPOSIT_RESERVE)}</TableCell>
      <TableCell className="px-0 capitalize">{formatBalanceToDisplay(balance.CHARGEBACK_RESERVE)}</TableCell>
    </TableRow>
  )
}

function formatBalanceToDisplay(balanceValues) {
  const localAmountToDisplay = currencyWithTwoDecimals(balanceValues.localAmount)
  const foreignCurrencyAmountToDisplay =
    balanceValues.foreignCurrency !== null && balanceValues.foreignCurrency !== undefined
      ? ` / ${currencyWithTwoDecimals(balanceValues.foreignCurrencyAmount, '$')}`
      : ''

  return `${localAmountToDisplay}${foreignCurrencyAmountToDisplay}`
}

export default Balances
