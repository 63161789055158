import React, { useState } from 'react'
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Grid, Autocomplete } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'

const SearchBarMultipleFilter = ({ componentFiltersConfig, setFilters }) => {
  const initialState = componentFiltersConfig.reduce((acc, filter) => {
    acc[filter.filterName] = ''
    return acc
  }, {})

  const [componentfilters, setComponentfilters] = useState(initialState)

  const handleChangeEvent = (event) => {
    setComponentfilters((prev) => ({ ...prev, [event.target.name]: event.target.value }))
  }

  const handleChangeValue = (filterName, value) => {
    setComponentfilters((prev) => ({ ...prev, [filterName]: value }))
  }

  const handleSearch = () => {
    const filters = Object.entries(componentfilters).reduce((acc, [key, value]) => {
      acc[key] = value && typeof value === 'object' && 'id' in value ? value.id : value
      return acc
    }, {})

    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value !== null && value !== undefined && value !== ''),
    )
    setFilters(filteredFilters)
  }

  const handleClear = () => {
    setComponentfilters(initialState)
    setFilters({})
  }

  const Filter = ({ type, options, filterName, displayName }) => {
    switch (type) {
      case 'SELECT':
        return (
          <FormControl fullWidth margin="dense">
            <InputLabel shrink>{displayName}</InputLabel>
            <Select
              key={filterName}
              name={filterName}
              value={componentfilters[filterName]}
              onChange={handleChangeEvent}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )

      case 'DATE':
        return (
          <TextField
            key={filterName}
            label={displayName}
            name={filterName}
            value={componentfilters[filterName]}
            onChange={handleChangeEvent}
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="dense"
            variant="outlined"
          />
        )

      case 'INPUT':
        return (
          <TextField
            key={filterName}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={displayName}
            name={filterName}
            value={componentfilters[filterName]}
            onChange={handleChangeEvent}
          />
        )

      case 'AUTOCOMPLETE':
        return (
          <Autocomplete
            key={filterName}
            options={options}
            renderInput={(params) => <TextField {...params} label={displayName} />}
            onChange={(_, newValue) => handleChangeValue(filterName, newValue)}
            value={componentfilters[filterName]}
          />
        )

      default:
        return <></>
    }
  }

  return (
    <Grid container spacing={2} alignItems="center">
      {componentFiltersConfig.map((filter) => (
        <Grid item xs={3} key={filter.filterName}>
          <Filter
            key={filter.filterName}
            type={filter.type}
            options={filter.options}
            filterName={filter.filterName}
            displayName={filter.displayName}
          />
        </Grid>
      ))}

      <Grid item container justifyContent="end" xs={12}>
        <Button variant="contained" color="error" onClick={handleClear} startIcon={<FontAwesomeIcon icon={faXmark} />}>
          Clear
        </Button>
        <Button variant="contained" color="info" onClick={handleSearch} startIcon={<FontAwesomeIcon icon={faSearch} />}>
          Search
        </Button>
      </Grid>
    </Grid>
  )
}

export default SearchBarMultipleFilter
