export default function DaysWithoutPaidTransaction({ lastPaidTransaction }) {
  if (lastPaidTransaction === undefined) {
    return <></>
  }
  const diffTime = Math.abs(new Date() - new Date(lastPaidTransaction))
  const daysWithoutPaidTransaction = Math.floor(diffTime / (1000 * 60 * 60 * 24))
  const color = getDaysWithoutPaidTransactionColor(daysWithoutPaidTransaction)
  return <small className={`text-white ${color} border-radius-4 px-10 py-10px`}>{daysWithoutPaidTransaction}</small>
}

function getDaysWithoutPaidTransactionColor(daysWithoutPaidTransaction) {
  if (daysWithoutPaidTransaction === undefined) {
    return undefined
  }
  if (daysWithoutPaidTransaction <= 30) {
    return 'bg-account-active'
  }
  if (daysWithoutPaidTransaction <= 90) {
    return 'bg-account-onanalysis'
  }
  if (daysWithoutPaidTransaction <= 180) {
    return 'bg-secondary'
  }
  return 'bg-dark'
}
