import {
  Box,
  CircularProgress,
  Collapse,
  Icon,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { SimpleCard } from '../../../../matx'
import { currencyWithTwoDecimals } from '../../../services/FormatterService'
import { useEffect, useState } from 'react'
import {
  executeWithdrawalInBatch,
  getSettlementsInBatchByStatus,
  accountEnums,
  getAllAccountingEnums,
} from '../../../services/APIClient'
import Loading from '../../../components/Loading'

import { Button, Col, Form, Row } from 'react-bootstrap'

const SettlementBatchToWithdrawal = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [approvedSettlementsContent, setApprovedSettlementsContent] = useState()

  useEffect(() => {
    const getSettlementsToWithdrawal = async () => {
      const resp = await getSettlementsInBatchByStatus('APPROVED')

      setApprovedSettlementsContent(resp)
      setIsLoading(false)
    }

    getSettlementsToWithdrawal()
  }, [])

  return (
    <div className="m-sm-30">
      <SimpleCard title="Settlement Batch B2B - To withdrawal">
        {isLoading && !approvedSettlementsContent ? (
          <Loading />
        ) : approvedSettlementsContent.batchs.length > 0 ? (
          <BatchTable batchSettlements={approvedSettlementsContent.batchs} />
        ) : (
          <TableRow>
            <TableCell>There is no approved settlement</TableCell>
          </TableRow>
        )}
      </SimpleCard>
    </div>
  )
}

const BatchTable = ({ batchSettlements }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell align="left" />
        <TableCell align="left">Batch ID</TableCell>
        <TableCell align="left">Total local amount</TableCell>
        <TableCell align="left">Total foreign currecy amount</TableCell>
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {batchSettlements.map((batchSettlement) => (
        <BatchTableRow key={batchSettlement.batchWithdrawalId} batchSettlement={batchSettlement} />
      ))}
    </TableBody>
  </Table>
)

const BatchTableRow = ({ batchSettlement }) => {
  const [open, setOpen] = useState(false)
  const [modalState, setModalState] = useState({ open: false })

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ maxWidth: 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
          </IconButton>
        </TableCell>
        <TableCell align="left">{batchSettlement.batchWithdrawalId}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(batchSettlement.batchWithdrawalLocalAmount, '')} BRL`}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(batchSettlement.batchWithdrawalForeignCurrencyAmount, '')} ${batchSettlement.batchWithdrawalForeignCurrency}`}</TableCell>
        <TableCell align="left">
          <Button
            className="btn btn-success"
            onClick={() => setModalState({ batchWithdrawalId: batchSettlement.batchWithdrawalId, open: true })}
          >
            Submit
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Settlements
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Id</TableCell>
                    <TableCell align="left">Externalized Id</TableCell>
                    <TableCell align="left">Local currency amount</TableCell>
                    <TableCell align="left">Foreing currency amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batchSettlement.settlements.map((settlement) => (
                    <TableRow key={settlement.id}>
                      <TableCell align="left">{settlement.id}</TableCell>
                      <TableCell align="left">{settlement.externalizedId}</TableCell>
                      <TableCell align="left">{`${currencyWithTwoDecimals(settlement.localAmount, '')} BRL`}</TableCell>
                      <TableCell align="left">{`${currencyWithTwoDecimals(settlement.foreignCurrencyAmount, '')} ${settlement.foreignCurrency}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={modalState.open}
        onClose={() => setModalState({ open: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2" style={{ marginBottom: 20 }}>
            Withdrawal information:
          </Typography>
          <ToWithdrawalForm batchWithdrawalId={modalState.batchWithdrawalId} />
        </Box>
      </Modal>
    </>
  )
}

const ToWithdrawalForm = ({ batchWithdrawalId }) => {
  const [validated, setValidated] = useState(false)
  const [requestForm, setRequestForm] = useState({
    batchWithdrawalId: batchWithdrawalId,
    executionDate: '',
    fxBank: 'TOPAZIO',
    fxCycle: 'D0',
    baseFx: '',
    fxNature: 'EFX_OF_GOODS_AND_SERVICES_34052',
    localCurrency: 'BRL',
    foreignCurrency: 'USD',
    error: {},
  })
  const [isLoading, setIsLoading] = useState(false)
  const [fxBanks, setFxBanks] = useState([])
  const [fxNatures, setFxNatures] = useState([])

  useEffect(() => {
    accountEnums().then((resp) => {
      setFxBanks(resp.fxBanks)
    })
    getAllAccountingEnums().then((resp) => {
      setFxNatures(resp.fxNatures)
    })
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
    } else {
      setIsLoading(true)
      await executeWithdrawalInBatch(requestForm)
      setIsLoading(false)
    }
  }

  function getDecimalPatter(numberType, decimalPlaces) {
    switch (numberType) {
      case 'MONEY':
        return new RegExp(`^\\d+(\\.\\d{${decimalPlaces}})$`)
      case 'RATE':
        return /^\d+(\.\d{4,10})$/
      default:
        throw new Error(`numberType must be 'MONEY' or 'RATE'`)
    }
  }

  function getErrorMessage(numberType, decimalPlaces) {
    switch (numberType) {
      case 'MONEY':
        return `Please enter a number with ${decimalPlaces} decimal places.`
      case 'RATE':
        return 'Please enter a number with up to 4 decimal places or down to 10 decimal places.'
      default:
        throw new Error(`numberType must be 'MONEY' or 'RATE'`)
    }
  }

  const handleNumberChange = (e, field, numberType, decimalPlaces = 2) => {
    const { value } = e.target
    const decimalPattern = getDecimalPatter(numberType, decimalPlaces)

    if (!decimalPattern.test(value)) {
      setRequestForm({
        ...requestForm,
        [field]: value,
        error: {
          ...requestForm.error,
          [field]: getErrorMessage(numberType, decimalPlaces),
        },
      })
    } else {
      setRequestForm({ ...requestForm, [field]: value, error: { ...requestForm.error, [field]: undefined } })
    }
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="executionDate">
          <Form.Label>Execution date</Form.Label>
          <Form.Control
            type="date"
            required
            onChange={(e) => setRequestForm({ ...requestForm, executionDate: e.target.value })}
            value={requestForm?.executionDate}
          />
          <Form.Control.Feedback type="invalid">Please fill it.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="fxBank">
          <Form.Label>FX bank</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setRequestForm({ ...requestForm, fxBank: e.target.value })}
            value={requestForm?.fxBank}
          >
            {fxBanks.map((fxBank) => (
              <option key={fxBank} value={fxBank}>
                {fxBank}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please fill it.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="fxCycle">
          <Form.Label>FX settlement cycle</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setRequestForm({ ...requestForm, fxCycle: e.target.value })}
            value={requestForm?.fxCycle}
          >
            <option value="D0">D0 (T+0)</option>
            <option value="D1">D1 (T+1)</option>
            <option value="D2">D2 (T+2)</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please fill it.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="baseFx">
          <Form.Label>Settlement base FX rate</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.baseFx}
            onChange={(e) => handleNumberChange(e, 'baseFx', 'RATE')}
            value={requestForm?.baseFx}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.baseFx || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="fxNature">
          <Form.Label>FX nature</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setRequestForm({ ...requestForm, fxNature: e.target.value })}
            value={requestForm?.fxNature}
          >
            {fxNatures.map((fxNature) => (
              <option key={fxNature} value={fxNature}>
                {fxNature}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="bankFxSpread">
          <Form.Label>Bank FX spread</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.bankFxSpread}
            onChange={(e) => handleNumberChange(e, 'bankFxSpread', 'RATE')}
            value={requestForm?.bankFxSpread}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.bankFxSpread || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="bankFxRate">
          <Form.Label>Bank FX rate</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.bankFxRate}
            onChange={(e) => handleNumberChange(e, 'bankFxRate', 'RATE')}
            value={requestForm?.bankFxRate}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.bankFxRate || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="bankFxTaxAmount">
          <Form.Label>Bank FX tax amount</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.bankFxTaxAmount}
            onChange={(e) => handleNumberChange(e, 'bankFxTaxAmount', 'MONEY', 3)}
            value={requestForm?.bankFxTaxAmount}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.bankFxTaxAmount || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="bankSettlementFeeInForeignCurrency">
          <Form.Label>Bank settlement fee in foreign currency</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.bankSettlementFeeInForeignCurrency}
            onChange={(e) => handleNumberChange(e, 'bankSettlementFeeInForeignCurrency', 'MONEY')}
            value={requestForm?.bankSettlementFeeInForeignCurrency}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.bankSettlementFeeInForeignCurrency || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="bankSettlementFee">
          <Form.Label>Bank settlement fee</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.bankSettlementFee}
            onChange={(e) => handleNumberChange(e, 'bankSettlementFee', 'MONEY', 3)}
            value={requestForm?.bankSettlementFee}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.bankSettlementFee || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="settlementAmountSentToFxBank">
          <Form.Label>Settlement amount sent to FX bank</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.settlementAmountSentToFxBank}
            onChange={(e) => handleNumberChange(e, 'settlementAmountSentToFxBank', 'MONEY')}
            value={requestForm?.settlementAmountSentToFxBank}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.settlementAmountSentToFxBank || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="settlementAmountSentToFxBankAfterDeductingFxCharges">
          <Form.Label>Settlement amount sent to FX bank after deducting FX charges</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.settlementAmountSentToFxBankAfterDeductingFxCharges}
            onChange={(e) => handleNumberChange(e, 'settlementAmountSentToFxBankAfterDeductingFxCharges', 'MONEY')}
            value={requestForm?.settlementAmountSentToFxBankAfterDeductingFxCharges}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.settlementAmountSentToFxBankAfterDeductingFxCharges || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="amountSentInForeignCurrency">
          <Form.Label>Amount sent in foreign currency</Form.Label>
          <Form.Control
            type="number"
            required
            isInvalid={!!requestForm.error.amountSentInForeignCurrency}
            onChange={(e) => handleNumberChange(e, 'amountSentInForeignCurrency', 'MONEY')}
            value={requestForm?.amountSentInForeignCurrency}
          />
          <Form.Control.Feedback type="invalid">
            {requestForm.error.amountSentInForeignCurrency || 'Please provide a valid number.'}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="foreignCurrency">
          <Form.Label>Foreign currency</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setRequestForm({ ...requestForm, foreignCurrency: e.target.value })}
            value={requestForm?.foreignCurrency}
          >
            <option value="USD">USD</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please fill it.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="localCurrency">
          <Form.Label>Local currency</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setRequestForm({ ...requestForm, localCurrency: e.target.value })}
            value={requestForm?.localCurrency}
          >
            <option value="BRL">BRL</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please fill it.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Button style={{ marginTop: 20 }} className="btn btn-success" type="submit">
        {isLoading ? <CircularProgress size={15} /> : 'Submit'}
      </Button>
    </Form>
  )
}

export default SettlementBatchToWithdrawal
