import { useEffect, useState } from 'react'
import { SimpleCard } from '../../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import { refundDetails } from '../../../services/APIClient'
import 'bootstrap/dist/css/bootstrap.min.css'
import { convertToBrazilianDateTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import RefundStatusForm from './RefundStatusForm'
import { useNavigate } from 'react-router-dom'

export default function RefundDetails({ id }) {
  const [details, setDetails] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    async function update() {
      setDetails(await refundDetails(id))
    }
    update()
  }, [id])

  const redirectToTransaction = (tid) => {
    navigate(`/payin/transaction/${tid}`)
  }

  const renderDefaultRefundResponse = () => {
    return (
      <>
        <tr>
          <td>Refund Id</td>
          <td>{id}</td>
        </tr>
        <tr>
          <td>Externalized Refund Id</td>
          <td>{details.externalId}</td>
        </tr>
        <tr>
          <td>Transaction id</td>
          <td>
            {details.tid}
            <button
              type="button"
              className="btn btn-info"
              onClick={async () => {
                redirectToTransaction(details.tid)
              }}
              style={{ marginLeft: 45 }}
            >
              See Transaction
            </button>
          </td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>{currencyWithTwoDecimals(details.amount)}</td>
        </tr>
        <tr>
          <td>Payment method</td>
          <td>{details.collectType}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{details.status}</td>
        </tr>
        <tr>
          <td>Reason</td>
          <td>{details.reason}</td>
        </tr>
        <tr>
          <td>Comment</td>
          <td>{details.comment}</td>
        </tr>
        <tr>
          <td>Date time</td>
          <td>{convertToBrazilianDateTimeZone(details.dateTime)}</td>
        </tr>
      </>
    )
  }

  const renderRefundResponse = () => {
    if (details.collectType === 'CREDIT_CARD') {
      return renderDefaultRefundResponse()
    } else if (details.beneficiary === undefined) {
      return <>{renderDefaultRefundResponse()}</>
    } else {
      return (
        <>
          {renderDefaultRefundResponse()}
          <tr>
            <td>Name</td>
            <td>{details.beneficiary.name}</td>
          </tr>
          <tr>
            <td>Document Number</td>
            <td>{details.beneficiary.document ? details.beneficiary.document : details.beneficiary.documentId} </td>
          </tr>
          <tr>
            <td>Bank info type</td>
            <td>{details.beneficiary.bankInfoType}</td>
          </tr>
          {details.beneficiary.bankInfoType === 'PIX' ? (
            <>
              <tr>
                <td>Pix Key</td>
                <td>{details.beneficiary.pix.key}</td>
              </tr>
              <tr>
                <td>Pix Key Type</td>
                <td>{details.beneficiary.pix.keyType}</td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>Bank Code</td>
                <td>{details.beneficiary.bankAccount.bankCode}</td>
              </tr>
              <tr>
                <td>Branch</td>
                <td>{details.beneficiary.bankAccount.branch}</td>
              </tr>
              <tr>
                <td>Account Number</td>
                <td>{details.beneficiary.bankAccount.accountNumber}</td>
              </tr>
              <tr>
                <td>Verifying Digit</td>
                <td>{details.beneficiary.bankAccount.verifyingDigit}</td>
              </tr>
            </>
          )}
        </>
      )
    }
  }

  return (
    <div className="First card">
      <SimpleCard title="Transaction Details">
        {details === null ? (
          <></>
        ) : (
          <>
            <div>
              <Container>
                <h5>Refund information:</h5>
                <BTable striped bordered hover>
                  <tbody>{renderRefundResponse()}</tbody>
                </BTable>
                <RefundHistoriesTable histories={details.histories} />
              </Container>
            </div>
            <div>
              <RefundStatusForm currentStatus={details.status} paymentMethod={details.collectType} refundId={id} />
            </div>
          </>
        )}
      </SimpleCard>
    </div>
  )
}

function RefundHistoriesTable({ histories }) {
  const sortedHistories = Object.entries(histories)
    .map(([status, dateTime]) => ({ status, dateTime }))
    .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))
  return (
    <>
      <h6>History:</h6>
      <BTable striped bordered hover>
        <tbody>
          {sortedHistories.map((item) => (
            <tr key={item.status}>
              <td>{item.status}</td>
              <td>{convertToBrazilianDateTimeZone(item.dateTime)}</td>
            </tr>
          ))}
        </tbody>
      </BTable>
    </>
  )
}
