import React, { useEffect, useState } from 'react'
import { Breadcrumb, SimpleCard } from '../../../../matx'
import {
  Box,
  Collapse,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { convertToBrazilianDateTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { getBankProviderReconciliation } from '../../../services/APIClient'
import Loading from '../../../components/Loading'
import TruncatedTextWithTooltip from '../../../components/TruncatedTextWithTooltip'

export default function BankReconciliationPage() {
  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Bank reconciliation' }]} />
      </div>
      <BankReconciliationByProvider provider="BS2" />
    </div>
  )
}

function BankReconciliationByProvider({ provider }) {
  return (
    <div className="m-sm-30">
      <SimpleCard
        title={
          <Typography variant="h4" gutterBottom component="div">
            {provider}
          </Typography>
        }
      >
        <BankReconciliationByAccountCategory provider={provider} bankAccountCategory={'B2B_TRADE'} />
      </SimpleCard>
    </div>
  )
}

function BankReconciliationByAccountCategory({ provider, bankAccountCategory }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="m-sm-30">
      <SimpleCard
        title={
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" gutterBottom component="div">
              {bankAccountCategory.replace('_', ' ')}
            </Typography>
            <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
            </IconButton>
          </div>
        }
      >
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <BankReconciliationByAccountType
            provider={provider}
            bankAccountCategory={bankAccountCategory}
            bankAccountType="PAYMENT_COLLECTION"
          />
          <BankReconciliationByAccountType
            provider={provider}
            bankAccountCategory={bankAccountCategory}
            bankAccountType="AVAILABLE"
          />
        </Collapse>
      </SimpleCard>
    </div>
  )
}

function BankReconciliationByAccountType({ provider, bankAccountCategory, bankAccountType }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="m-sm-30">
      <SimpleCard
        title={
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h7" gutterBottom component="div">
              {bankAccountType.replace('_', ' ')}
            </Typography>
            <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
            </IconButton>
          </div>
        }
      >
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <BankReconciliation
            provider={provider}
            bankAccountCategory={bankAccountCategory}
            bankAccountType={bankAccountType}
          />
        </Collapse>
      </SimpleCard>
    </div>
  )
}

function BankReconciliation({ provider, bankAccountCategory, bankAccountType }) {
  const [dailyReconciliationRows, setDailyReconciliationRows] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    let mounted = true
    getBankProviderReconciliation(provider, bankAccountCategory, bankAccountType)
      .then((response) => {
        if (mounted) {
          setDailyReconciliationRows(response)
        }
      })
      .finally(() => {
        if (mounted) setIsLoading(false)
      })
    return () => (mounted = false)
  }, [provider, bankAccountCategory, bankAccountType])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Table>
      <TableHead>
        <TableCell align="left" />
        <TableCell>Balance match</TableCell>
        <TableCell align="left">Provider date</TableCell>
        <TableCell align="left">Provider balance with income</TableCell>
        <TableCell align="left">Provider balance without income</TableCell>
        <TableCell align="left">System balance</TableCell>
        <TableCell align="left">Provider delta without income</TableCell>
        <TableCell align="left">System delta</TableCell>
        <TableCell align="left">Provider income amount</TableCell>
        <TableCell align="left">Matches</TableCell>
      </TableHead>
      <TableBody>
        {dailyReconciliationRows.map((reconciliationOfTheDay) => (
          <DailyReconciliationRow
            key={reconciliationOfTheDay.date}
            balanceStatus={reconciliationOfTheDay.balanceStatus}
            reconciliationOfTheDay={reconciliationOfTheDay}
            shouldShowBalanceMatch={bankAccountType !== 'PAYMENT_COLLECTION'}
          />
        ))}
      </TableBody>
    </Table>
  )
}

function DailyReconciliationRow({ balanceStatus, reconciliationOfTheDay, shouldShowBalanceMatch }) {
  const [isOpen, setIsOpen] = useState(false)

  const matchesSummary = reconciliationOfTheDay.statements
    .filter((entry) => entry.eventType !== 'INCOME')
    .reduce(
      (result, entry) => {
        if (isMatched(entry.status)) {
          result.matched += 1
        } else {
          result.notMatched += 1
        }
        return result
      },
      { matched: 0, notMatched: 0 },
    )

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ maxWidth: 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {shouldShowBalanceMatch ? <BalanceMatch balanceStatus={balanceStatus} /> : <></>}
        </TableCell>
        <TableCell align="left">{reconciliationOfTheDay.brazilianDate}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(reconciliationOfTheDay.providerBalanceWithIncome, '')} BRL`}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(reconciliationOfTheDay.providerBalanceWithoutIncome, '')} BRL`}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(reconciliationOfTheDay.systemBalance, '')} BRL`}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(reconciliationOfTheDay.providerDeltaWithoutIncome, '')} BRL`}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(reconciliationOfTheDay.systemDelta, '')} BRL`}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(reconciliationOfTheDay.providerIncomeAmount, '')} BRL`}</TableCell>
        <TableCell align="left">
          <div>
            <div>
              {matchesSummary.matched} <FontAwesomeIcon icon={faCircleCheck} color="green" />
            </div>
            <div>
              {matchesSummary.notMatched === 0 ? (
                <></>
              ) : (
                <>
                  {matchesSummary.notMatched} <FontAwesomeIcon icon={faTriangleExclamation} color="red" />
                </>
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ maxWidth: 0 }} />
                    <TableCell align="left">Provider event datetime</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Provider amount</TableCell>
                    <TableCell align="left">System amount</TableCell>
                    <TableCell align="left">Entry type</TableCell>
                    <TableCell align="left">Provider externalized ids</TableCell>
                    <TableCell align="left">Event</TableCell>
                    <TableCell align="left">System id</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reconciliationOfTheDay.statements.map((entry) => (
                    <TableRow key={entry.id}>
                      <TableCell align="left">{defineMatcherComponent(entry)}</TableCell>
                      <TableCell align="left">{convertToBrazilianDateTimeZone(entry.eventDateTime)}</TableCell>
                      <TableCell align="left">
                        <TruncatedTextWithTooltip text={entry?.description || ''} />
                      </TableCell>
                      <TableCell align="left">{`${currencyWithTwoDecimals(entry.providerAmount, '')} BRL`}</TableCell>
                      <TableCell align="left">{`${currencyWithTwoDecimals(entry.systemAmount, '')} BRL`}</TableCell>
                      <TableCell align="left">{entry.accountingEntryType}</TableCell>
                      <TableCell align="left">
                        <TruncatedTextWithTooltip text={entry.providerExternalizedIds?.join(',') || ''} />
                      </TableCell>
                      <TableCell align="left">
                        <TruncatedTextWithTooltip text={entry?.eventType} />
                      </TableCell>
                      <TableCell align="left">{entry?.systemEventId}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function BalanceMatch({ balanceStatus }) {
  if (isMatched(balanceStatus)) {
    return <FontAwesomeIcon icon={faCircleCheck} color="green" />
  }
  return <FontAwesomeIcon icon={faTriangleExclamation} color="red" />
}

function isMatched(status) {
  return status === 'RECONCILIATED' || status === 'DIVERGENCE_SOLVED'
}

function defineMatcherComponent(entry) {
  if (entry.eventType === 'INCOME') {
    return <></>
  }

  if (isMatched(entry.status)) {
    return <FontAwesomeIcon icon={faCircleCheck} color="green" title={entry.status} />
  } else {
    return <FontAwesomeIcon icon={faTriangleExclamation} color="red" title={entry.status} />
  }
}
