import { useState, useEffect } from 'react'
import { createFxLockTransactionsCsv, allAccountsInfo } from '../../../services/APIClient'
import { Autocomplete, TextField } from '@mui/material'

export default function FxLockTransactionCsv() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [allMerchants, setAllMerchants] = useState([])
  const [merchant, setMerchant] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    allAccountsInfo().then((response) => {
      const merchants = response.result.map((merchant) => {
        return { label: `${merchant.id} - ${merchant.softDescriptor}`, id: merchant.id }
      })
      setAllMerchants(merchants)
    })
  }, [])

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isLoading])

  const handleDownloadClick = () => {
    setIsLoading(true)
    createFxLockTransactionsCsv(startDate, endDate, merchant?.id)
      .catch(() => alert('Something went wrong. Report could not be downloaded. Please, contact system administrator.'))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onDateChange = (propertyName, newValue) => {
    if (propertyName === 'startDate') {
      setStartDate(newValue)
    } else if (propertyName === 'endDate') {
      setEndDate(newValue)
    }
  }

  return (
    <div className="card-modal-report">
      <div style={{ paddingLeft: '30px' }}>
        <div>
          <label>Fx lock transactions not settled and ready for withdraw</label>
        </div>
        <div className="row w-100 mx-auto">
          <div className="col-md-4 col-4">
            <label className="labelCalendarDistance">Initial date:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              onChange={(event) => onDateChange('startDate', event.target.value)}
            />
          </div>
          <div className="col-md-4 col-4">
            <label className="labelCalendarDistance">End date:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              onChange={(event) => onDateChange('endDate', event.target.value)}
            />
          </div>
          <div className="col-md-6 col-6">
            <Autocomplete
              includeInputInList
              options={allMerchants}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Merchant" />}
              value={merchant}
              onChange={(_, newValue) => setMerchant(newValue)}
              isOptionEqualToValue={(option, value) => option.id + '' === value.id}
            />
          </div>
          <div className="col-md-3 col-3">
            <button
              className="buttonSendDate"
              onClick={handleDownloadClick}
              disabled={startDate === null || endDate === null || isLoading}
            >
              Download csv
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
