import {
  Box,
  CircularProgress,
  Collapse,
  Icon,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { SimpleCard } from '../../../../matx'
import { currencyWithTwoDecimals } from '../../../services/FormatterService'
import { useEffect, useState } from 'react'
import {
  executeFundSentInBatch,
  getSettlementsInBatchByStatus,
  downloadAcamFileBatchWithdrawalId,
  getSettlementFxDetailsByBatchWithdrawalId,
} from '../../../services/APIClient'
import Loading from '../../../components/Loading'

import { Button, Col, Form, Row, Dropdown } from 'react-bootstrap'

const SettlementBatchToFundSend = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [withdrawalSettlementsContent, setWithdrawalSettlementsContent] = useState()

  useEffect(() => {
    const getSettlementsToFundSent = async () => {
      const resp = await getSettlementsInBatchByStatus('WITHDRAWN')

      setWithdrawalSettlementsContent(resp)
      setIsLoading(false)
    }

    getSettlementsToFundSent()
  }, [])

  return (
    <div className="m-sm-30">
      <SimpleCard title="Settlement Batch B2B - To fund send">
        {isLoading && !withdrawalSettlementsContent ? (
          <Loading />
        ) : withdrawalSettlementsContent.batchs.length > 0 ? (
          <BatchTable batchSettlements={withdrawalSettlementsContent.batchs} />
        ) : (
          <TableRow>
            <TableCell>There is no withdrawn settlement</TableCell>
          </TableRow>
        )}
      </SimpleCard>
    </div>
  )
}

const BatchTable = ({ batchSettlements }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell align="left" />
        <TableCell align="left">Batch ID</TableCell>
        <TableCell align="left">Total local amount</TableCell>
        <TableCell align="left">Total foreign currecy amount</TableCell>
        <TableCell align="left">FX execution date BRT</TableCell>
        <TableCell align="left">Funds sent date BRT</TableCell>
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {batchSettlements.map((batchSettlement) => (
        <BatchTableRow key={batchSettlement.batchWithdrawalId} batchSettlement={batchSettlement} />
      ))}
    </TableBody>
  </Table>
)

const BatchTableRow = ({ batchSettlement }) => {
  const [open, setOpen] = useState(false)
  const [modalState, setModalState] = useState({ open: false })

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ maxWidth: 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
          </IconButton>
        </TableCell>
        <TableCell align="left">{batchSettlement.batchWithdrawalId}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(batchSettlement.batchWithdrawalLocalAmount, '')} BRL`}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(batchSettlement.batchWithdrawalForeignCurrencyAmount, '')} ${batchSettlement.batchWithdrawalForeignCurrency}`}</TableCell>
        <TableCell align="left">{batchSettlement.fxExecutionDateBrt}</TableCell>
        <TableCell align="left">{batchSettlement.fundsSentDateBrt}</TableCell>
        <TableCell align="left">
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Download ACAM
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  variant="success"
                  onClick={() => downloadAcamFileBatchWithdrawalId(batchSettlement.batchWithdrawalId, 'DEFAULT')}
                >
                  Default format
                </Dropdown.Item>
                <Dropdown.Item
                  variant="success"
                  onClick={() => downloadAcamFileBatchWithdrawalId(batchSettlement.batchWithdrawalId, 'TOPAZIO_ACAM')}
                >
                  Topazio
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              className="btn btn-success"
              onClick={() => setModalState({ batchWithdrawalId: batchSettlement.batchWithdrawalId, open: true })}
            >
              Submit
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Settlements
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Id</TableCell>
                    <TableCell align="left">Externalized Id</TableCell>
                    <TableCell align="left">Local currency amount</TableCell>
                    <TableCell align="left">Foreing currency amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batchSettlement.settlements.map((settlement) => (
                    <TableRow key={settlement.id}>
                      <TableCell align="left">{settlement.id}</TableCell>
                      <TableCell align="left">{settlement.externalizedId}</TableCell>
                      <TableCell align="left">{`${currencyWithTwoDecimals(settlement.localAmount, '')} BRL`}</TableCell>
                      <TableCell align="left">{`${currencyWithTwoDecimals(settlement.foreignCurrencyAmount, '')} ${settlement.foreignCurrency}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={modalState.open}
        onClose={() => setModalState({ open: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2" style={{ marginBottom: 20 }}>
            Fund sent information:
          </Typography>
          <ToFundSentForm batchWithdrawalId={modalState.batchWithdrawalId} />
        </Box>
      </Modal>
    </>
  )
}

const ToFundSentForm = ({ batchWithdrawalId }) => {
  const [validated, setValidated] = useState(false)
  const [requestForm, setRequestForm] = useState({
    batchWithdrawalId: batchWithdrawalId,
    uertInSwiftMessage: '',
    fundSentDate: '',
    fxContractId: '',
    error: {},
  })
  const [isLoading, setIsLoading] = useState(false)
  const [fxBank, setFxBank] = useState()

  useEffect(() => {
    getSettlementFxDetailsByBatchWithdrawalId(batchWithdrawalId).then((resp) => {
      setFxBank(resp.fxBank)
    })
  }, [batchWithdrawalId])

  const handleSubmit = async (event) => {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
    } else {
      setIsLoading(true)
      await executeFundSentInBatch(requestForm)
      setIsLoading(false)
    }
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="fundsettlementFundSentDateSentDate">
          <Form.Label>Fund sent date</Form.Label>
          <Form.Control
            type="date"
            required
            onChange={(e) => setRequestForm({ ...requestForm, fundSentDate: e.target.value })}
            value={requestForm?.fundSentDate}
          />
          <Form.Control.Feedback type="invalid">Please fill it.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="fxContractId">
          <Form.Label>FX contract id</Form.Label>
          <Form.Control
            type="text"
            required
            onChange={(e) => setRequestForm({ ...requestForm, fxContractId: e.target.value })}
            value={requestForm?.fxContractId}
          />
          <Form.Control.Feedback type="invalid">Please fill it.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="settlementUERTInSwiftMessage">
          <Form.Label>UETRInSwiftMessage</Form.Label>
          <Form.Control
            type="text"
            required={fxBank !== 'SANTANDER' && fxBank !== 'TRAVELEX' && fxBank !== 'BS2'}
            onChange={(e) => setRequestForm({ ...requestForm, uertInSwiftMessage: e.target.value })}
            value={requestForm?.uertInSwiftMessage}
          />
          <Form.Control.Feedback type="invalid">Please fill it.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Button style={{ marginTop: 20 }} className="btn btn-success" type="submit">
        {isLoading ? <CircularProgress size={15} /> : 'Submit'}
      </Button>
    </Form>
  )
}

export default SettlementBatchToFundSend
