import { useEffect, useState, useCallback } from 'react'
import { Divider, Grid, Switch, TablePagination } from '@mui/material'
import Loading from '../../app/components/Loading'
import { SCROLLBAR_NAME } from '../../app/MatxLayout/Layout1/Layout1'

import SearchBarMultipleFilter from '../../app/components/SearchBarMultipleFilter'
import SearchBarTextFilter from '../../app/components/SearchBarTextFilter'

function PaginationTable({
  queryItems,
  tableName,
  tableContent,
  attributesToFilter,
  hasSailorSwitch = true,
  hasSearchBarMultipleFilter = false,
}) {
  const [itemsList, setItemsList] = useState([])
  const [itemsFiltered, setItemsFiltered] = useState([])
  const [listLength, setListLength] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(200)
  const [page, setPage] = useState(0)

  const [isLoading, setIsLoading] = useState(true)
  const [queryHasFailed, setQueryHasFailed] = useState(false)
  const [filters, setFilters] = useState(hasSearchBarMultipleFilter ? {} : { isSailor: false })

  const updateRowsPerPage = useCallback(
    (page, rowsPerPage, filters) => {
      setIsLoading(true)
      setPage(page)
      setRowsPerPage(rowsPerPage)
      setListLength(0)
      setItemsList([])

      queryItems(page, rowsPerPage, filters)
        .then((resp) => {
          if (resp !== undefined) {
            setListLength(resp.totalElements)
            setItemsList(resp.content)
            setItemsFiltered(resp.content)
            setIsLoading(false)
          }
        })
        .catch(() => {
          setQueryHasFailed(true)
          setItemsList([])
          setIsLoading(false)
        })
    },
    [queryItems],
  )

  useEffect(() => {
    const scrollbarDiv = document.querySelector(`.${SCROLLBAR_NAME}`)
    if (scrollbarDiv) {
      scrollbarDiv.scrollTop = 0
    }
  }, [page])

  useEffect(() => {
    updateRowsPerPage(page, rowsPerPage, filters)
  }, [page, rowsPerPage, filters, updateRowsPerPage])

  if (queryHasFailed) {
    return <div>Something went wrong</div>
  }

  return (
    <div>
      <div
        className="MuiToolbar-root MuiToolbar-regular jss8044 MuiToolbar-gutters"
        role="toolbar"
        aria-label="Table Toolbar"
      >
        <div className="jss8046 titleWithSearchBox">
          <div className="jss8050" aria-hidden="true">
            <h4 className="MuiTypography-root jss8051 MuiTypography-h6">{tableName}</h4>
          </div>
        </div>

        {hasSailorSwitch && !hasSearchBarMultipleFilter ? (
          <div>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>Not sailor</Grid>
              <Grid item>
                <Switch onChange={(_, checked) => setFilters({ isSailor: checked })} color="secondary" />
              </Grid>
              <Grid item>Sailor</Grid>
            </Grid>
          </div>
        ) : (
          <></>
        )}

        {hasSearchBarMultipleFilter ? (
          <>
            <SearchBarMultipleFilter componentFiltersConfig={attributesToFilter} setFilters={setFilters} />
            <Divider sx={{ marginBottom: '30px', marginTop: '30px' }}></Divider>
          </>
        ) : (
          <SearchBarTextFilter
            attributesToFilter={attributesToFilter}
            items={itemsList}
            setItemsFiltered={setItemsFiltered}
          ></SearchBarTextFilter>
        )}
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-100 overflow-auto">
          {tableContent(itemsFiltered)}

          <TablePagination
            className="px-16"
            rowsPerPageOptions={[200, parseInt(listLength / 4), parseInt(listLength / 2), parseInt(listLength)]}
            component="div"
            count={listLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </div>
      )}
    </div>
  )
}

export default PaginationTable
