import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

const SearchBarTextFilter = ({ items, attributesToFilter, setItemsFiltered }) => {
  const [filters, setFilters] = useState([])
  const [query, setQuery] = useState('')

  const filterItems = useCallback(
    (filters, allItems) => {
      if (filters.length === 0) {
        setItemsFiltered(allItems)
        return
      }
      let reducer = (previousValue, currentValue) =>
        previousValue.filter((items) => {
          return attributesToFilter.some((attributeToFilter) => filterNullable(items[attributeToFilter], currentValue))
        })

      let filtered = filters.map((filter) => filter.toLowerCase()).reduce(reducer, allItems)
      if (filtered.length > 0) {
        setItemsFiltered(filtered)
      } else {
        toast.info(`No item found with these filters on this page, we're back with all items!`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setItemsFiltered(allItems)
      }
    },
    [attributesToFilter, setItemsFiltered],
  )

  const removeFilterHandler = (_, filter) => {
    let newArray = [...filters]
    let index = newArray.indexOf(filter)
    if (index !== -1) {
      newArray.splice(index, 1)
      setFilters(newArray)
    }
    filterItems(newArray, items)
  }

  const saveFilter = () => {
    let newFilters = [...filters, query]
    setQuery('')
    setFilters(newFilters)
    filterItems(newFilters, items)
  }

  function filterNullable(value, currentValue) {
    if (value === undefined || value === '') {
      return false
    }
    return value.toLowerCase().includes(currentValue)
  }

  return (
    <div className="main_search">
      {filters.map((filter) => (
        <button key={filter} className="buttonFilter" onClick={(event) => removeFilterHandler(event, filter)}>
          {filter}
        </button>
      ))}
      <input
        value={query}
        className="main_search_input"
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Buscar!"
      />
      <button className="search_button" onClick={saveFilter}>
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </div>
  )
}

export default SearchBarTextFilter
