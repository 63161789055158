import { Component } from 'react'
import { SimpleCard } from '../../../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { accountDetails, transactionPagination, getMerchantTransactionsSummary } from '../../../../services/APIClient'
import { countryCodeToCountryName } from '../../../../services/CountryUtils'
import { currencyWithTwoDecimals, convertToBrazilianDateTimeZone } from '../../../../services/FormatterService'
import FeesDetails from './FeeDetails'
import ManagementCredentials from './ManagementCredentials'
import Balances from './Balances'
import CreditPeriods from './CreditPeriods'
import WebhookDetails from './WebhookDetails'
import GuaranteeDepositDetails from './GuaranteeDepositDetails'
import FxBanksThatApprovedMerchantDetails from './FxBanksThatApprovedMerchantDetails'
import CommentsDetails from './CommentsDetails'
import Loading from '../../../../components/Loading'
import { NavLink } from 'react-router-dom'
import PaginatedTable from '../../../../components/PaginatedTable'
import DaysWithoutPaidTransaction from './DaysWithoutPaidTransaction'

class AccountDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      details: null,
      transactionsSummary: null,
      credentialsInfo: null,
      id: this.props.id,
      isLoading: true,
    }
  }

  async componentDidMount() {
    try {
      const details = await accountDetails(this.state.id)
      const transactionsSummary = await getMerchantTransactionsSummary(this.state.id)
      this.setState({ details, transactionsSummary, isLoading: false })
    } catch (error) {
      alert('Something went wrong. Please, contact system administrator.')
      this.setState({ isLoading: false })
    }
  }

  getGuaranteeDepositInfo = (fees) => {
    return fees
      .filter((fee) => fee.feeCategory === 'GUARANTEE_DEPOSIT')
      .map((guaranteeDepositFee) => ({
        paymentMethod: guaranteeDepositFee.feeSelector,
        installmentsCategory: guaranteeDepositFee.creditCard?.installmentsCategory,
        days: guaranteeDepositFee.guaranteeDeposit.daysUntilExpiration,
        effectiveDate: guaranteeDepositFee.effectiveDate,
      }))
  }

  getExternalizedId = () => {
    if (this.state.details.externalizedId == null) {
      return this.state.id
    }
    return this.state.details.externalizedId
  }

  render() {
    return (
      <SimpleCard title="Account Details">
        {this.state.isLoading && this.state.details === null ? (
          <Loading />
        ) : (
          <Container>
            <h5>Account:</h5>
            <BTable striped bordered hover>
              <tbody>
                <tr>
                  <td>Id</td>
                  <td>{this.state.details.id}</td>
                </tr>
                <tr>
                  <td>Externalized id</td>
                  <td>{this.getExternalizedId()}</td>
                </tr>
                <tr>
                  <td>tradeName</td>
                  <td>{this.state.details.tradeName}</td>
                </tr>
                <tr>
                  <td>softDescriptor</td>
                  <td>{this.state.details.softDescriptor}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{this.state.details.type}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{this.state.details.status}</td>
                </tr>
                <tr>
                  <td>Primary document</td>
                  <td>{this.state.details.primaryDocument}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{this.state.details.name}</td>
                </tr>
                <tr>
                  <td>Settlement currency</td>
                  <td>{this.state.details.settlementCurrency}</td>
                </tr>
                <tr>
                  <td>MCC</td>
                  <td>{this.state.details.mcc}</td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>{this.state.details.website}</td>
                </tr>
                <tr>
                  <td>Product Type Description</td>
                  <td>{this.state.details.productTypeDescription}</td>
                </tr>
                <tr>
                  <td>Business Line</td>
                  <td>{this.state.details.businessLine}</td>
                </tr>
                <tr>
                  <td>Business Model Description</td>
                  <td>{this.state.details.businessModelDescription}</td>
                </tr>
                <tr>
                  <td>Cnae</td>
                  <td>{this.state.details.cnae}</td>
                </tr>
                <tr>
                  <td>Tax regime</td>
                  <td>{this.state.details.taxRegime}</td>
                </tr>
                <tr>
                  <td>Legal nature</td>
                  <td>{this.state.details.legalNature}</td>
                </tr>
                <tr>
                  <td>Branch activity</td>
                  <td>{this.state.details.branchActivity}</td>
                </tr>
                <tr>
                  <td>Billing</td>
                  <td>{this.state.details.billing}</td>
                </tr>
                <tr>
                  <td>Created At</td>
                  <td>{convertToBrazilianDateTimeZone(this.state.details.createdAt)}</td>
                </tr>
                <tr>
                  <td>Comments</td>
                  <td>{this.state.details.comments}</td>
                </tr>
              </tbody>
            </BTable>
            <AddressDetails address={this.state.details.address} />
            <BankDetails bank={this.state.details.bank} bankType={this.state.details.bank.type} />
            {this.state.details.documents.map((document, index) => (
              <DocumentInfo key={index} document={document} />
            ))}
            <FxBanksThatApprovedMerchantDetails
              fxBanksThatApprovedMerchant={this.state.details.fxBanksThatApprovedMerchant}
            />
            {this.state.details.contacts.map((contact, index) => (
              <ContactInfo key={index} contact={contact} />
            ))}
            {this.state.details.partners.map((partner, index) => (
              <PartnerInfo key={index} partner={partner} />
            ))}
            {this.state.details.ubos.map((ubo, index) => (
              <UboInfo key={index} ubo={ubo} />
            ))}
            <TransactionsInfo transactionsSummary={this.state.transactionsSummary} merchantId={this.state.id} />
            <Balances merchantId={this.state.id} />
            <CreditPeriods creditPeriods={this.state.details.settlementPeriods} />
            <GuaranteeDepositDetails guaranteeDeposits={this.getGuaranteeDepositInfo(this.state.details.fees)} />
            <FeesDetails fees={this.state.details.fees.filter((fee) => fee.feeCategory !== 'GUARANTEE_DEPOSIT')} />
            <WebhookDetails webhooks={this.state.details.webhooks} />
            <ManagementCredentials
              accountId={this.state.id}
              externalizedId={this.getExternalizedId()}
              softDescriptor={this.state.details.softDescriptor}
              isApproved={this.state.details.status === 'ACTIVE'}
            />
            <CommentsDetails comments={this.state.details.comments} />
          </Container>
        )}
      </SimpleCard>
    )
  }
}

class AddressDetails extends Component {
  render() {
    const { address } = this.props
    if (typeof address === 'undefined') return <React.Fragment />

    return (
      <React.Fragment>
        <h5>Address:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Address</td>
              <td>
                {address.street},&nbsp;
                {address.neighbourhood},&nbsp;
                {address.number}&nbsp;
                {address.complement} -&nbsp;
                {address.city}-&nbsp;
                {address.zipCode},&nbsp;
                {address.state}&nbsp; ({countryCodeToCountryName(address.countryCode)})
              </td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class BankDetails extends Component {
  render() {
    const { bank, bankType } = this.props
    if (typeof bank === 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    if (bankType === 'BRAZIL') {
      return <BrazilBank bank={bank.brazil} />
    } else {
      return <GlobalBank bank={bank.global} />
    }
  }
}

class GlobalBank extends Component {
  render() {
    const { bank } = this.props
    if (typeof bank == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Global bank info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Bank Code</td>
              <td>{bank.bankName}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{bank.accountNumber}</td>
            </tr>
            <tr>
              <td>Swift Code</td>
              <td>{bank.swiftCode}</td>
            </tr>
            <tr>
              <td>Beneficiary name</td>
              <td>{bank.beneficiaryName}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class BrazilBank extends Component {
  render() {
    const { bank } = this.props
    if (typeof bank == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Brazil Bank info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Bank Code</td>
              <td>{bank.bankCode}</td>
            </tr>
            <tr>
              <td>Branch</td>
              <td>{bank.agency}</td>
            </tr>
            <tr>
              <td>Verifying Digit</td>
              <td>{bank.verifyingDigit}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{bank.accountNumber}</td>
            </tr>
            <tr>
              <td>Pix</td>
              <td>{bank.pix}</td>
            </tr>
            <tr>
              <td>Beneficiary name</td>
              <td>{bank.beneficiaryName}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class DocumentInfo extends Component {
  render() {
    const { document } = this.props
    if (typeof document == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Document Info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Document Id</td>
              <td>{document.id}</td>
            </tr>
            <tr>
              <td>Document Number</td>
              <td>{document.document}</td>
            </tr>
            <tr>
              <td>Document Type</td>
              <td>{document.type}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

function TransactionsInfo({ transactionsSummary, merchantId }) {
  const hasCreditCard = transactionsSummary.paymentMethodsUsed?.includes('CREDIT_CARD')
  return (
    <>
      <h5>Transactions info:</h5>
      <BTable striped bordered hover>
        <tbody>
          <tr>
            <td>First paid transaction at</td>
            <td>{convertToBrazilianDateTimeZone(transactionsSummary.firstPaidTransactionAt)}</td>
          </tr>
          <tr>
            <td>Last paid transaction at</td>
            <td>{convertToBrazilianDateTimeZone(transactionsSummary.lastPaidTransactionAt)}</td>
          </tr>
          <tr>
            <td>DaysWithoutPaidTransaction</td>
            <td>
              <DaysWithoutPaidTransaction lastPaidTransaction={transactionsSummary.lastPaidTransactionAt} />
            </td>
          </tr>
          <tr>
            <td>Number of paid transactions</td>
            <td>{transactionsSummary.paidTransactionsCount}</td>
          </tr>
          <tr>
            <td>UsedPaymentMethodsInPaidTransactions</td>
            <td>{transactionsSummary.paymentMethodsUsed?.join(', ')}</td>
          </tr>
          {hasCreditCard && (
            <tr>
              <td>Max number of installments used</td>
              <td>{transactionsSummary.maxInstallmentsOfPaidTransactions}</td>
            </tr>
          )}
        </tbody>
      </BTable>
      <LastPayinTransactions merchantId={merchantId} />
    </>
  )
}

class ContactInfo extends Component {
  render() {
    const { contact } = this.props
    if (typeof contact == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Contact Info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>name</td>
              <td>{contact.name}</td>
            </tr>
            <tr>
              <td>email</td>
              <td>{contact.email}</td>
            </tr>
            <tr>
              <td>telephone</td>
              <td>{contact.telephone}</td>
            </tr>
            <tr>
              <td>position</td>
              <td>{contact.position}</td>
            </tr>
            <tr>
              <td>sector</td>
              <td>{contact.sector}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class PartnerInfo extends Component {
  render() {
    const { partner } = this.props
    if (typeof partner == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Partner Info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>pep</td>
              <td>{partner.pep ? 'True' : 'False'}</td>
            </tr>
            <tr>
              <td>document</td>
              <td>{partner.document}</td>
            </tr>
            <tr>
              <td>documentType</td>
              <td>{partner.documentType}</td>
            </tr>
            <tr>
              <td>participation</td>
              <td>{partner.participation}</td>
            </tr>
            <tr>
              <td>documentIssuer</td>
              <td>{partner.documentIssuer}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class UboInfo extends Component {
  render() {
    const { ubo } = this.props
    if (typeof ubo == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Partner Info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>pep</td>
              <td>{ubo.pep ? 'True' : 'False'}</td>
            </tr>
            <tr>
              <td>name</td>
              <td>{ubo.name}</td>
            </tr>
            <tr>
              <td>documentType</td>
              <td>{ubo.documentType}</td>
            </tr>
            <tr>
              <td>document</td>
              <td>{ubo.document}</td>
            </tr>
            <tr>
              <td>documentIssuer</td>
              <td>{ubo.documentIssuer}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

function LastPayinTransactions({ merchantId }) {
  const columns = ['tid', 'Payment method', 'Status', 'Amount', 'Creation date']

  const TransactionRows = ({ content }) =>
    content.map((transaction) => (
      <tr key={transaction.id}>
        <td>
          <NavLink to={`/payin/transaction/${transaction.id}`} end>
            {transaction.id}
          </NavLink>
        </td>
        <td>{transaction.paymentMethod}</td>
        <td>{transaction.status}</td>
        <td>{currencyWithTwoDecimals(transaction.amount)}</td>
        <td>{convertToBrazilianDateTimeZone(transaction.createdAt)}</td>
      </tr>
    ))

  return (
    <>
      <h5 style={{ paddingTop: '30px' }}>Last payin transactions:</h5>
      <PaginatedTable
        queryParams={{ merchantId }}
        query={transactionPagination}
        columns={columns}
        Rows={TransactionRows}
      />
    </>
  )
}

export default AccountDetails
