import { useEffect, useState } from 'react'
import { getBalancesByProvider } from '../../../services/APIClient'
import Loading from '../../../components/Loading'
import { SimpleCard } from '../../../../matx'
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Collapse,
} from '@mui/material'
import { currencyWithTwoDecimals } from '../../../services/FormatterService'

export default function BalanceByProvider({ date }) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(false)
  }, [date])

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <h3>Balances by provider:</h3>
        <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
        </IconButton>
      </div>
      {isOpen && <BalanceByProviderBody date={date} />}
    </>
  )
}

function BalanceByProviderBody({ date }) {
  const [balanceByProvider, setBalanceByProvider] = useState(undefined)
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    setIsloading(true)
    let mounted = true
    getBalancesByProvider(date)
      .then((balanceByProviderResponse) => {
        if (mounted) {
          setBalanceByProvider(balanceByProviderResponse)
        }
      })
      .finally(() => {
        if (mounted) setIsloading(false)
      })
    return () => (mounted = false)
  }, [date])

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      {balanceByProvider === undefined ? (
        <div>An error has occured</div>
      ) : (
        <div style={{ display: 'grid', gridRowGap: '10px' }}>
          <BalanceByProviderTable
            title="Balance amounts"
            balanceByProvider={balanceByProvider.nonReserveBalances}
            allBalanceSummaries={balanceByProvider.allBalanceSummaries}
          />
          <BalanceByProviderTable
            title="Reserve balance amounts"
            balanceByProvider={balanceByProvider.reserveBalances}
            allBalanceSummaries={balanceByProvider.allBalanceSummaries}
          />
        </div>
      )}
    </>
  )
}

function BalanceByProviderTable({ title, balanceByProvider, allBalanceSummaries }) {
  return (
    <div className="First card">
      <SimpleCard title={title}>
        <Table style={{ whiteSpace: 'pre' }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell className="px-0">Business line</TableCell>
              <TableCell className="px-0">Balance type</TableCell>
              <TableCell className="px-0">Bank</TableCell>
              <TableCell className="px-0">Merchants count</TableCell>
              <TableCell className="px-0">Total (BRL)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {balanceByProvider.map((item) => (
              <BalanceByProviderTableRow item={item} allBalanceSummaries={allBalanceSummaries} />
            ))}
          </TableBody>
        </Table>
      </SimpleCard>
    </div>
  )
}

function BalanceByProviderTableRow({ item, allBalanceSummaries }) {
  const [open, setOpen] = useState(false)

  const merchantBalances = allBalanceSummaries
    .filter((balance) => balance.balanceType === item.balanceType)
    .filter((balance) => balance.businessLine === item.businessLine)
    .filter((balance) => balance.provider === item.provider)

  const backgroundColor = getRowCollor(item)

  return (
    <>
      <TableRow key={`row${item.balanceType}-${item.businessLine}-${item.provider}`}>
        <TableCell style={{ maxWidth: 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
          </IconButton>
        </TableCell>
        <TableCell key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-businesLine`} className="px-0">
          {item.businessLine}
        </TableCell>
        <TableCell key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-balanceType`} className="px-0">
          {item.balanceType}
        </TableCell>
        <TableCell key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-provider`} className="px-0">
          {item.provider}
        </TableCell>
        <TableCell
          key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-merchantCount`}
          className="px-0"
        >
          {item.merchantCount}
        </TableCell>
        <TableCell
          key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-amount`}
          className={`px-0 ${backgroundColor}`}
        >
          {currencyWithTwoDecimals(item.amount)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Merchant id</TableCell>
                    <TableCell align="left">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {merchantBalances.map((merchantBalance) => (
                    <TableRow key={merchantBalance.id}>
                      <TableCell align="left">{merchantBalance.merchantId}</TableCell>
                      <TableCell align="left">{`${currencyWithTwoDecimals(merchantBalance.amount, '')} BRL`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function getRowCollor(balance) {
  if (
    [
      'GAMING',
      'IMPORTATION',
      'PHYSICAL_GOODS_USING_PAYMENT_LINK',
      'DROPSHIPPING',
      'VIRTUAL_GOODS_WITH_LOW_TRANSACTION_AMOUNT',
    ].includes(balance.businessLine)
  ) {
    return 'bg-paid'
  } else if (balance.balanceType === 'GUARANTEE_DEPOSIT_RESERVE' || balance.balanceType === 'SCHEDULED') {
    return 'bg-paid'
  } else {
    return 'bg-denied'
  }
}
