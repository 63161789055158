import { SimpleCard } from '../../../../matx'
import PayinTransactionTableContent from './PayinTransactionTableContent'
import { transactionPagination, transactionEnums, allAccountsInfo } from '../../../services/APIClient'
import PaginationTable from '../../../../matx/components/PaginationTable'
import { useEffect, useState } from 'react'
import Loading from '../../../components/Loading'

function TransactionTable() {
  const [componentFilters, setComponentFilters] = useState(undefined)

  useEffect(() => {
    const getFilterInformation = async () => {
      const [transactionEnumsResponse, merchants] = await Promise.all([transactionEnums(), allAccountsInfo()])

      const merchantOptions = merchants.result.map((merchant) => {
        return { label: `${merchant.id} - ${merchant.softDescriptor}`, id: merchant.id }
      })

      const enums = {
        paymentMethods: transactionEnumsResponse.paymentsMethod,
        currencies: transactionEnumsResponse.currency,
        businessLines: transactionEnumsResponse.businessLine,
        accountingEntryTypes: transactionEnumsResponse.accountingEntryType,
        fxBanks: transactionEnumsResponse.fxBanks,
        transactionStatus: transactionEnumsResponse.transactionStatus,
        transactionStatusDetails: transactionEnumsResponse.transactionStatusDetails,
        externalizedTransactionStatus: transactionEnumsResponse.externalizedTransactionStatus,
        externalizedTransactionStatusDetails: transactionEnumsResponse.externalizedTransactionStatusDetails,
      }

      setComponentFilters([
        { type: 'DATE', displayName: 'Start created at', filterName: 'startCreatedAt' },
        { type: 'DATE', displayName: 'End created at', filterName: 'endCreatedAt' },
        { type: 'DATE', displayName: 'Start updated at', filterName: 'startUpdatedAt' },
        { type: 'DATE', displayName: 'End updated at', filterName: 'endUpdatedAt' },
        {
          type: 'SELECT',
          displayName: 'Payment method',
          filterName: 'paymentMethod',
          options: enums.paymentMethods,
        },
        {
          type: 'SELECT',
          displayName: 'Business line',
          filterName: 'businessLine',
          options: enums.businessLines,
        },
        { type: 'SELECT', displayName: 'Status', filterName: 'status', options: enums.transactionStatus },
        {
          type: 'SELECT',
          displayName: 'Status details',
          filterName: 'statusDetails',
          options: enums.transactionStatusDetails,
        },
        { type: 'INPUT', displayName: 'Externalized ID', filterName: 'externalizedId' },
        { type: 'INPUT', displayName: 'Merchant order ID', filterName: 'merchantOrderId' },
        { type: 'INPUT', displayName: 'Referece', filterName: 'reference' },
        { type: 'INPUT', displayName: 'E-mail', filterName: 'email' },
        {
          type: 'AUTOCOMPLETE',
          displayName: 'Soft descriptor',
          filterName: 'merchantId',
          options: merchantOptions,
        },
        {
          type: 'AUTOCOMPLETE',
          displayName: 'Intermediary soft descriptor',
          filterName: 'intermediaryId',
          options: merchantOptions,
        },
      ])
    }

    getFilterInformation()
  }, [])

  return (
    <div className="m-sm-30">
      <div className="py-12" />
      <SimpleCard>
        {componentFilters === undefined ? (
          <Loading />
        ) : (
          <PaginationTable
            queryItems={transactionPagination}
            tableName="Payin Transactions"
            tableContent={(filteredItems) => <PayinTransactionTableContent items={filteredItems} />}
            attributesToFilter={componentFilters}
            hasSearchBarMultipleFilter
          />
        )}
      </SimpleCard>
    </div>
  )
}

export default TransactionTable
