import React, { useEffect, useState } from 'react'
import { SimpleCard } from '../../../../matx'
import { accountPagination, getFirstAndLastPaidTransactionOfAllMerchants } from '../../../services/APIClient'
import PaginationTable from '../../../../matx/components/PaginationTable'
import AccountTableContent from './AccountTableContent'
import Loading from '../../../components/Loading'

export default function AccountTable() {
  const [paidTransactionsAtByMerchantId, setPaidTransactionsAtByMerchantId] = useState(undefined)
  useEffect(() => {
    getFirstAndLastPaidTransactionOfAllMerchants().then((response) =>
      setPaidTransactionsAtByMerchantId(response.paidTransactionsAtByMerchantId),
    )
  }, [])
  return (
    <div className="m-sm-30">
      <div className="py-12" />
      <SimpleCard>
        {paidTransactionsAtByMerchantId === undefined ? (
          <Loading />
        ) : (
          <PaginationTable
            queryItems={accountPagination}
            tableName="Accounts"
            tableContent={(filteredItems) => (
              <AccountTableContent
                items={filteredItems}
                paidTransactionsAtByMerchantId={paidTransactionsAtByMerchantId}
              />
            )}
            attributesToFilter={['softDescriptor', 'tradeName', 'status']}
          />
        )}
      </SimpleCard>
    </div>
  )
}
